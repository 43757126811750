import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const BlankPage = lazy(() => import('../../container/pages/BlankPage'));
const Placements = lazy(() => import('../../container/placement'));
const Template = lazy(() => import('../../container/templates'));
const Trigger = lazy(() => import('../../container/trigger'));
const Gallery = lazy(() => import('../../container/galleryImages'));
const LandingImg = lazy(() => import('../../container/landingImage'));
const Review = lazy(() => import('../../container/review'));
const Blog = lazy(() => import('../../container/blog'));
const PlacementCompanies = lazy(() =>
  import('../../container/placementCompanies')
);
const FleetList = lazy(() => import('../../container/fleetList'));
const News = lazy(() => import('../../container/news'));
const Certificates = lazy(() => import('../../container/certificates'));
const QuizList = lazy(() => import('../../container/quiz/list'));
const QuizView = lazy(() => import('../../container/quiz/view'));
const Users = lazy(() => import('../../container/users'));
const FreshLead = lazy(() => import('../../container/students/fresh-leads'));
const AssignedFreshLead = lazy(() =>
  import('../../container/students/assigned-leads')
);
const OnlineStudent = lazy(() =>
  import('../../container/students/online-student')
);
const ExamAssigned = lazy(() =>
  import('../../container/students/exam-assigned')
);
const OfflineStudent = lazy(() =>
  import('../../container/students/offline-student')
);
const EligibilityTestSubmit = lazy(() =>
  import('../../container/students/eligibility-test-submit')
);
const AllStudentResult = lazy(() =>
  import('../../container/students/all-student-result')
);
const InterviewAssigned = lazy(() =>
  import('../../container/students/interview-assigned')
);
const ResultView = lazy(() =>
  import('../../container/students/components/resultView')
);
const ReadyForInterview = lazy(() =>
  import('../../container/students/ready-for-interview')
);
const RescheduleINterview = lazy(() =>
  import('../../container/students/reschedule-interview')
);
const FinalPassedStudent = lazy(() =>
  import('../../container/students/final-passed-student')
);
const EnrolledStudent = lazy(() =>
  import('../../container/students/enrolled-student')
);
const OldPortalEnrolled = lazy(() =>
  import('../../container/students/old-portal-enrolled')
);
const StudentView = lazy(() =>
  import('../../container/students/components/enrolledStudentView')
);
const SoftDeletedStudent = lazy(() =>
  import('../../container/students/soft-deleted-students')
);
const InterestedStudents = lazy(() =>
  import('../../container/students/interested-students')
);

function PagesRoute() {
  return (
    <Routes>
      <Route index element={<BlankPage />} />
      <Route path="/placements" element={<Placements />} />
      <Route path="/templates" element={<Template />} />
      <Route path="/triggers" element={<Trigger />} />
      <Route path="/home/gallery-images" element={<Gallery />} />
      <Route path="/home/landing-images" element={<LandingImg />} />
      <Route path="/home/reviews" element={<Review />} />
      <Route path="/home/blogs" element={<Blog />} />
      <Route
        path="/home/placement-companies"
        element={<PlacementCompanies />}
      />
      <Route path="/home/fleet-list" element={<FleetList />} />
      <Route path="/home/news" element={<News />} />
      <Route path="/certificates" element={<Certificates />} />
      <Route path="/quiz" element={<QuizList />} />
      <Route path="/quiz/:quizId" element={<QuizView />} />
      <Route path="/users" element={<Users />} />
      <Route path="/fresh-leads" element={<FreshLead />} />
      <Route path="/assigned-leads" element={<AssignedFreshLead />} />
      <Route path="/online-students" element={<OnlineStudent />} />
      <Route path="/exam-assigned" element={<ExamAssigned />} />
      <Route path="/offline-students" element={<OfflineStudent />} />
      <Route
        path="/eligibility-test-submit"
        element={<EligibilityTestSubmit />}
      />
      <Route path="/all-student-results" element={<AllStudentResult />} />
      <Route path="/results/:studentId" element={<ResultView />} />
      <Route path="/interview-assigned" element={<InterviewAssigned />} />
      <Route path="/ready-for-interview" element={<ReadyForInterview />} />
      <Route path="/reschedule-interview" element={<RescheduleINterview />} />
      <Route path="/final-passed-students" element={<FinalPassedStudent />} />
      <Route path="/enrolled-students" element={<EnrolledStudent />} />
      <Route path="/enrolled-students/:studentId" element={<StudentView />} />
      <Route path="/old-portal-enrolled" element={<OldPortalEnrolled />} />
      <Route path="/soft-deleted-students" element={<SoftDeletedStudent />} />
      <Route path="/interested-students" element={<InterestedStudents />} />
    </Routes>
  );
}

export default PagesRoute;
