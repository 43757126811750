import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
import BlankPage from "../container/pages/BlankPage";

const Login = lazy(() => import('../container/profile/authentication/SignIn'));
// const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));

const AuthRoot = () => {
  const navigate = useNavigate();

  useEffect(() => navigate('/'));
};

const FrontendRoutes = React.memo(() => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="*" element={<BlankPage />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);
