import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';
import EnvelopeAdd from '@iconscout/react-unicons/icons/uil-envelope-add';
import {
  UilBagAlt,
  UilImages,
  UilLayerGroup,
  UilClipboardAlt,
  UilDocumentLayoutLeft,
  UilUsdCircle,
  UilSetting,
  UilHome,
  UilUser,
} from '@iconscout/react-unicons';
import Cookies from 'js-cookie';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();
  const roleId = +Cookies.get('roleId');

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== 'recharts'
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const studentsChildMenu = [
    {
      label: 'Fresh Leads',
      route: 'fresh-leads',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Assigned Fresh Leads',
      route: 'assigned-leads',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Online Student',
      route: 'online-students',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Exam Assigned',
      route: 'exam-assigned',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Offline Student',
      route: 'offline-students',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Eligibility Test Submit',
      route: 'eligibility-test-submit',
      icon: <UilLayerGroup />,
    },
    {
      label: 'All Student Result',
      route: 'all-student-results',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Interview Assigned',
      route: 'interview-assigned',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Ready For Interview',
      route: 'ready-for-interview',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Reschedule Interview',
      route: 'reschedule-interview',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Final Passed Student',
      route: 'final-passed-students',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Enrolled With HBIMS',
      route: 'enrolled-students',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Old Portal Enrolled',
      route: 'old-portal-enrolled',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Soft Deleted Student',
      route: 'soft-deleted-students',
      icon: <UilLayerGroup />,
    },
    {
      label: 'Interested Student',
      route: 'interested-students',
      icon: <UilLayerGroup />,
    },
  ];

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/placements`}>
        {t('Placements')}
      </NavLink>,
      'placements',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/placements`}>
          <UilUsersAlt />
        </NavLink>
      )
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/certificates`}>
        {t('Certificates')}
      </NavLink>,
      'certificates',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/certificates`}>
          <UilUsdCircle />
        </NavLink>
      )
    ),
    getItem(t('Communication'), 'communications', !topMenu && <EnvelopeAdd />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/templates`}>
          {t('Templates')}
        </NavLink>,
        'templates',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/templates`}>
            <UilDocumentLayoutLeft />
          </NavLink>
        )
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/triggers`}>
          {t('Triggers')}
        </NavLink>,
        'triggers',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/triggers`}>
            <UilDocumentLayoutLeft />
          </NavLink>
        )
      ),
    ]),
    getItem(t('Home'), 'home', !topMenu && <UilHome />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/home/landing-images`}>
          Landing Images
        </NavLink>,
        'landing-images',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/home/landing-images`}>
            <UilLayerGroup />
          </NavLink>
        )
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/home/gallery-images`}>
          {t('Gallery Images')}
        </NavLink>,
        'gallery-images',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/home/gallery-images`}>
            <UilImages />
          </NavLink>
        )
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/home/reviews`}>
          {t('Reviews')}
        </NavLink>,
        'reviews',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/home/reviews`}>
            <UilClipboardAlt />
          </NavLink>
        )
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/home/blogs`}>
          {t('Blogs')}
        </NavLink>,
        'blogs',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/home/blogs`}>
            <UilDocumentLayoutLeft />
          </NavLink>
        )
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/home/placement-companies`}
        >
          {t('Placement Companies')}
        </NavLink>,
        'placement-companies',
        !topMenu && (
          <NavLink
            className="menuItem-iocn"
            to={`${path}/home/placement-companies`}
          >
            <UilDocumentLayoutLeft />
          </NavLink>
        )
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/home/fleet-list`}>
          {t('Fleet List')}
        </NavLink>,
        'fleet-list',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/home/fleet-list`}>
            <UilDocumentLayoutLeft />
          </NavLink>
        )
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/home/news`}>
          {t('News')}
        </NavLink>,
        'news',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/home/news`}>
            <UilDocumentLayoutLeft />
          </NavLink>
        )
      ),
    ]),
    getItem(
      t('Students'),
      'students',
      !topMenu && <UilUser />,
      studentsChildMenu.map((item) => {
        return getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/${item.route}`}>
            {item.label}
          </NavLink>,
          item.route,
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/${item.route}`}>
              {item.icon}
            </NavLink>
          )
        );
      })
    ),
    getItem(t('Quizzes'), 'quizzes', !topMenu && <UilBagAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/quiz`}>
          {t('Quiz')}
        </NavLink>,
        'quiz',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/quiz`}>
            <UilDocumentLayoutLeft />
          </NavLink>
        )
      ),
    ]),
    getItem(t('Settings'), 'settings', !topMenu && <UilSetting />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
          {t('Users')}
        </NavLink>,
        'users',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/users`}>
            <UilUsersAlt />
          </NavLink>
        )
      ),
    ]),
  ];

  const AgentMenuRoutes = studentsChildMenu.filter(
    (item) =>
      item.route !== 'fresh-leads' &&
      item.route !== 'assigned-leads' &&
      item.route !== 'final-passed-students' &&
      item.route !== 'enrolled-students' &&
      item.route !== 'soft-deleted-students' &&
      item.route !== 'online-students' &&
      item.route !== 'offline-students' &&
      item.route !== 'old-portal-enrolled'
  );

  const AgentMenu = AgentMenuRoutes.map((item) => {
    return getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/${item.route}`}>
        {item.label}
      </NavLink>,
      item.route,
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/${item.route}`}>
          {item.icon}
        </NavLink>
      )
    );
  });

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? 'home'
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]
          : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={roleId === 2 ? AgentMenu : items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
